.range-wrap {
  width: 100%;
  position: relative;
  overflow: visible;
  box-sizing: border-box;
  margin-bottom: 7px;
  margin-top: 2px;
}

input[type=range] {
  -webkit-appearance: none;
  margin: 20px 0 0 0;
  width: 100%;
}

input[type=range]:focus {
  outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  animate: 0.2s;
  background: #43A1A2;
  border-radius: 25px;
}

input[type=range]::-webkit-slider-thumb {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 1);
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -8px;
}

input[type=range]:focus::-webkit-slider-runnable-track {
  background: #43A1A2;
}

.range-value {
  position: absolute;
  top: -30%;
}

.range-value span {
  line-height: 24px;
  text-align: center;
  color: #000;
  font-size: 12px;
  display: block;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
}