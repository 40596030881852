body {
  margin: 0 !important;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

div {
  box-sizing: border-box;
}

/* Left in place to avoid btn resize */
.css-1mzzuk6 {
  box-sizing: content-box !important;
}

html {
  box-sizing: border-box;
}

/* Width */
div::-webkit-scrollbar {
    width: 1vw;
}

/* Track */
div::-webkit-scrollbar-track {
    box-shadow: inset 0 0 4px #ADADAD;
    border-radius: 0px;
}

/* Handle #43a1a2 or #214F61 or #ADADAD */
div::-webkit-scrollbar-thumb {
    box-shadow: inset 4px 2px 12px 2px #ADADAD;
    border-radius: 4px;
}

/* Width */
body::-webkit-scrollbar {
    width: 1vw;
}

/* Track */
body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 4px #214F61;
    border-radius: 0px;
}

/* Handle #43a1a2 or #214F61 or #ADADAD */
body::-webkit-scrollbar-thumb {
    box-shadow: inset 4px 2px 12px 2px #214F61;
    border-radius: 4px;
}
