.alfabetSoup {
    width: 100%;
    height: fit-content !important;
    margin-top: 15px;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    justify-content: center;
    margin: auto;
}

.alfabetSoup .game {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.alfabetSoup .game .gameInfo {
    display: flex;
    flex-direction: column;
    width: 90%;
    align-items: center;

    /* @media (max-width: 992px) {
        width: 100%;
    } */
}

.alfabetSoup .game .gameInfo p {
    margin: 0;
}

.alfabetSoup .game .gameInfo .marker p {
    display: inline-block;
    font-weight: bold;
    margin: 1px
}

.alfabetSoup .game .gameInfo .marker .finded {
    color: rgba(0, 107, 108, 0.8)
}

.alfabetSoup .game .gameInfo .marker .not-finded {
    color: rgba(245, 128, 0, 0.8)
}

.alfabetSoup .game .gameInfo .timer {
    margin: 20px;
    background-color: rgb(0, 126, 123);
    color: white;
    font-size: 20px;
    text-align: center;
    border-radius: 15px;
    width: 90%;

    @media (max-width: 992px) {
        width: 60%;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    display: inline;
}

.alfabetSoup .game .gameInfo .words {
    width: 70%;
    font-size: 25px;
    text-align: center;
}

.alfabetSoup .game .gameInfo .words .searched {
    text-decoration: line-through;
}

.alfabetSoup .game .gameCanvas {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

}

.alfabetSoup .game .gameCanvas canvas {
    width: 85% !important;
    border: 1px solid rgb(171, 171, 171) !important;

}

.alfabetSoup .game .edition-part {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1rem;

}

.alfabetSoup .game .edition-part .input-range {
    width: 80%;
    box-sizing: border-box;
}